<template>
  <div class="question">
    <label>
      <span v-if="tooltip" class="question-title" v-tooltip="tooltip">{{ question }}</span>
      <span v-else class="question-title">{{ question }}</span>

      <input v-if="edit" type="text" class="longtext" :value="surveyData[name]" @input="setText(name, $event)" required>
      <span v-else class="d-block fake-input fake-input-text">{{ surveyData[name] }}</span>
    </label>
  </div>
</template>

<script>

import surveyMixin from '@/mixins/surveyMixin.js';

export default {
  mixins: [surveyMixin],
  props: {
    question: {
      type: String,
      required: true,
    },
    tooltip: {
      type: String,
      required: false,
    },
    name: {
      type: String,
      required: true,
    },
    edit: {
      type: Boolean,
      default: true,
    },
  },
};

</script>
